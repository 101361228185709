import React from 'react'
import DocumentCard from './DocumentCard'
import ServiceCard from './ServiceCard'
import RelatedPost from './RelatedPost'

const BlogSidebar = ({ post, posts }) => {
    const { documents, event, services } = post

    return (
        <div className='widget-area'>
            {services.length > 0
                ?
                <div className='widget widget_services'>
                    <h3 className='widget-title'>Servicios</h3>
                    {services.map((service) => {
                        return (
                            <ServiceCard
                                key={`service-${service.id}`}
                                service={service}
                            />
                        )
                    })}
                </div>
                : null
            }
            {documents.length > 0
                ?
                <div className='widget widget_documents'>
                    <h3 className='widget-title'>Documentos</h3>
                    <ul>
                        {documents.map((document) => {
                            return document.media.map((media) => {
                                return (
                                    <DocumentCard
                                        key={`media-${media.id}`}
                                        media={media}
                                    />
                                )
                            })
                        })}
                    </ul>
                </div>
                : null
            }
            {/* <div className='widget widget_search'>
                <h3 className='widget-title'>Buscar</h3>
                <form className='search-form'>
                    <label>
                        <input type='search' className='search-field' placeholder='Búsqueda...' />
                    </label>
                    <button type='submit'>
                        <i className='bx bx-search-alt'></i>
                    </button>
                </form>
            </div> */}
            {posts.length > 0 ?
                <div className='widget widget_tracer_posts_thumb'>
                    <h3 className='widget-title'>{event ? 'Próximos Eventos' : 'Últimas Noticias'}</h3>
                    {posts.map((post) => (
                        <RelatedPost
                            key={`post-${post.id}`}
                            post={post}
                        />
                    ))}
                </div>
                :
                null
            }
        </div>
    )
}

export default BlogSidebar