import React, { useEffect, useState } from 'react'
import Layout from '../components/App/Layout'
import PageBanner from '../components/Common/PageBanner'
import BlogDetailsContent from '../components/BlogDetails/BlogDetailsContent'
import { getPost } from '../api/post'
import { navigate } from 'gatsby'

const BlogDetails = ({ location }) => {
    const [post, setPost] = useState(null)
    const [posts, setPosts] = useState(null)

    const params = new URLSearchParams(location.search)
    const slug = params.get('slug')

    useEffect(() => {
        (async () => {
            try {
                const data = await getPost(slug)
                setPost(data.post)
                setPosts(data.posts)
            } catch (error) {
                navigate('/404')
            }
        })()
    }, [slug])

    if (!post) return null
    
    
    var featured_image = post.media.filter(media => media.collection_name === 'featured_images');
    var image=null;
    if(featured_image.length !== 0){
        image=featured_image[0].original_url
    }
    

    return (
        <Layout title={post.title} image={image}>
            <PageBanner />
            <BlogDetailsContent
                post={post}
                posts={posts}
            />
        </Layout>
    )
}

export default BlogDetails