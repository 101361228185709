import React from 'react'
import { Link } from 'gatsby'
import { format } from 'date-fns'

const RelatedPost = ({ post }) => {
    const { media, published_at, slug, title, link  } = post
    
    var featured_image = media.filter(media => media.collection_name === 'featured_images');

    return (
        <article className='item'>
            <Link to={link ? link :`/blog-details?slug=${slug}`} target={link ? '_blank' : '_self'} className='thumb'>
                {featured_image.length !== 0 ?
                    <img src={featured_image[0].original_url} alt={title} />
                    :
                    null
                }
            </Link>
            <div className='info'>
                <span>{  format(new Date(published_at), 'dd/MM/yyyy HH:mm')}</span>
                <h4 className='title usmall'>
                    <Link to={link ? link :`/blog-details?slug=${slug}`} target={link ? '_blank' : '_self'}>
                        {title}
                    </Link>
                </h4>
            </div>
        </article>
    )
}

export default RelatedPost