import React from 'react'
import { Link } from 'gatsby'
import BlogDetailsSidebar from './BlogDetailsSidebar'
import SocialButtons from '../Common/SocialButtons'
import { format } from 'date-fns'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";

const BlogDetailsContent = ({ post, posts }) => {
    const { body, categories, media, published_at, slug, title } = post
    
    var featured_image = media.filter(media => media.collection_name === 'featured_images');

    return (
        <section className='blog-details-area ptb-50'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-8 col-md-12'>
                        <div className='blog-details-desc'>
                            {featured_image.length !== 0 ?
                                <div className='article-image'>
                                    <img src={featured_image[0].original_url} alt={title} />
                                </div>
                                : null
                            }
                            <div className='article-content'>
                                <div className='entry-meta'>
                                    <ul>
                                        <li>
                                            <i className='bx bx-calendar'></i>
                                            <span>Publicación</span>
                                            {  format(new Date(published_at), 'dd/MM/yyyy HH:mm')}
                                        </li>
                                    </ul>
                                </div>
                                <h3>{title}</h3>
                                <div dangerouslySetInnerHTML={{ __html: body }} />
                                <div>
                                {media.length > 1 ?
                                <Swiper
                                    loop={true}
                                    autoplay={{
                                        delay: 3500,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Autoplay, Pagination]}
                                    className="mySwiper2"
                                >
                                    {media.slice(0, 5).map((item, index) => {
                                        return (
                                            <SwiperSlide key={`image-swiper-${index}`}>
                                                <img src={item.original_url} alt={title} />
                                            </SwiperSlide>
                                        )
                                    })}
                                </Swiper>
                                : null
                            }
                                </div>
                            </div>
                            <div className='article-footer'>
                                {categories.length > 0 ?
                                    <div className='article-tags'>
                                        <div>
                                            <span><i className='bx bx-purchase-tag'></i></span>
                                            {categories.map((category) => {
                                                return (
                                                    <Link key={`category-${category.id}`} to={`/blog?category=${category.slug}`}>
                                                        {category.name}
                                                    </Link>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                                <div className='article-share'>
                                    <SocialButtons
                                        url={`${process.env.URL}/blog-details?slug=${slug}`}
                                        name={title}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-12'>
                        <BlogDetailsSidebar
                            post={post}
                            posts={posts}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BlogDetailsContent