import React from 'react'
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, LinkedinShareButton, LinkedinIcon, WhatsappShareButton, WhatsappIcon } from 'react-share'

const SocialButtons = ({ url, name }) => {
    return (
        <ul className='social'>
            <li style={{ marginRight: '.3em' }}>
                <WhatsappShareButton
                    url={url}
                    title={name}
                    className='Demo__some-network__share-button'
                >
                    <WhatsappIcon size={32} round />
                </WhatsappShareButton>
            </li>
            <li style={{ marginRight: '.3em' }}>
                <FacebookShareButton
                    url={url}
                    quote={name}
                    // hashtag={'#hashtag'}
                    description={name}
                    className='Demo__some-network__share-button'
                >
                    <FacebookIcon size={32} round />
                </FacebookShareButton>
            </li>
            <li style={{ marginRight: '.3em' }}>
                <TwitterShareButton
                    title={name}
                    url={url}
                    // hashtags={['hashtag1', 'hashtag2']}
                >
                    <TwitterIcon size={32} round />
                </TwitterShareButton>
            </li>
            <li>
                <LinkedinShareButton
                    url={url}
                    title={name}
                    summary={name}
                    className='Demo__some-network__share-button'
                >
                    <LinkedinIcon size={32} round />
                </LinkedinShareButton>
            </li>
        </ul>
    )
}

export default SocialButtons