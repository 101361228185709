import React from 'react'
import { Link } from 'gatsby'

const DocumentCard = ({ media }) => {
    const { name, original_url } = media
    
    return (
        <li>
            <Link to={original_url} target='_blank'>
                {name} <i className='bx bxs-file-pdf'></i>
            </Link>
        </li>
    )
}

export default DocumentCard